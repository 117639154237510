.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header {
  height: 4rem;
}

a.button.active {
  border: 1px solid var(--fore-color);
}

#corridor-panel > button.active {
  border: 1px solid black;
}

.corridor-info {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 320px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  margin: 5px;
  font-size: 12px;
}

.header {
  font-weight: bold;
  padding-right: 10px;
}

.tooltip {
  position: absolute;
        margin: 8px;
        padding: 4px;
        background: rgba(255, 255, 255, 0.8);
        color: #000;
        max-width: 300px;
        font-size: 10px;
        z-index: 9;
        pointer-events: none;
        border: 1px solid black;
}
